.BackgroundVideo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: hidden;
}

.Video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
