@import url('https://fonts.cdnfonts.com/css/minecraftia');

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  background-color: #f0f0f0;
  overflow: auto;
}

.fade-enter {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.fade-exit {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}
